import Link from "next/link";
import LanguageDropdown from "./LanguageDropdown";

const LoginHeader = () => {

  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark px-1 px-lg-5">
        <div className="logo-wrapper mr-5">
          <div className="navbar-brand-box">
            <Link href="/" className="logo logo-dark">
              <a className="d-flex align-items-center" style={{width: '150px'}}>
                <div className="logo-sm">
                  <img
                    src="/assets/images/logo-white.svg"
                    alt="Unlimited spin to win"
                    height={33}
                    style={{width: '100%'}}
                  />
                </div>
              </a>
            </Link>
          </div>
        </div>
        <div className="navbar-nav d-flex justify-content-end">
          <div className="navbar-user-menu pr-2 pr-xl-0">
            <LanguageDropdown/>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default LoginHeader