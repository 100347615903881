import React, {useState,useEffect} from "react"

import {Row, Col, CardBody, Card, Alert, Container, Label, FormGroup, FormFeedback, Input, Badge} from "reactstrap"

import Link from 'next/link'
import Image from 'next/image'
import {useForm} from "react-hook-form";
import LoginHeader from "../components/LoginHeader";
import profile from "./../public/assets/images/profile-img.png"
import Logo from "./../public/assets/images/unlimited-icon.svg"
import classnames from 'classnames'
import {getFirebaseHandler} from "../utils/firebaseHandler";
import {AuthAction, withAuthUser} from "next-firebase-auth";
import FullPageLoader from "../components/FullPageLoader";
import {useTranslation} from "react-i18next";
import {useRouter} from "next/router";

const Login = props => {
  const {register,handleSubmit, formState: { errors }} = useForm({
    mode:'onChange'
  })
  const {t} = useTranslation()
  const [invalid, setInvalid] = useState(false)
  const router = useRouter()
  // handleValidSubmit
  const handleValidSubmit = async (fieldValues) => {
    try{
      const {email,password} = fieldValues
      await getFirebaseHandler().loginUser(email, password)
      //props.loginUser(values, props.history)
    }catch(e){
      setInvalid(true)
    }
  }

  const showErrors = id => {
    const errorType = errors[id]?.type
    if(!errorType)
      return null

    switch(errorType){
      case 'required':
        return <span>{t('This is required')}</span>
      case 'pattern':
        return <span>{t('Invalid email address')}</span>
    }
  }

  return (
    <>
      <LoginHeader />
      <div className="account-pages">
        <Container className="">
          <Row className="justify-content-center align-items-center" style={{height:'calc(100vh - 60px)',minHeight:'660px'}}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('Welcome Back !')}</h5>
                        <p>{t('Sign in to continue to Unlimited spin to win.')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <Image src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link href="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <Logo
                              style={{width:"100%",height:"100%"}}
                            className="rounded-circle w-100"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {invalid &&<Alert color="danger">
                      {t('Wrong email or password!')}
                    </Alert>}
                    <form
                      className="form-horizontal"
                      onSubmit={handleSubmit(handleValidSubmit)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <FormGroup>
                        <Label>{t('Email')}</Label>
                        <input
                          {...register('email', {required:true,pattern:/^.+@.+$/})}
                          className={classnames({'form-control':true,'is-invalid': !!showErrors('email')})}
                          placeholder={t("Enter email")}
                          type="email"
                        />
                        <FormFeedback>
                          {showErrors('email')}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup className="mb-3">
                        <Label>{t('Password')}</Label>
                        <input
                          {...register('password', {required:true})}
                          className={classnames({'form-control':true,'is-invalid': !!showErrors('password')})}
                          type="password"
                          required
                          placeholder={t("Enter password")}
                        />
                        <FormFeedback>
                          {showErrors('password')}
                        </FormFeedback>
                      </FormGroup>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          {t('Remember me')}
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          {t('Log In')} <i className="mdi mdi-login" />
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link href="/forgot-password" className="text-muted">
                          <a>
                            <i className="mdi mdi-lock me-1" />
                            {t('Forgot your password?')}
                          </a>
                        </Link>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  {t('Don\'t have an account ?')}{' '}
                  <Link
                    href="/register"
                    className="fw-medium text-primary"
                  >
                    <a>
                      <span className="fw-medium text-primary">{' '}{t('Signup now')}</span>
                    </a>
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()} Unlimited Spin to Win <i className="mdi mdi-heart text-danger" /> by Peakdigital
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
			<style jsx>{`
			 :global(body){min-width: unset;}`}
			</style>
    </>
  )
}

export default withAuthUser({
  whenUnauthedBeforeInit: AuthAction.SHOW_LOADER,
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  LoaderComponent: FullPageLoader,
})(Login)